@use "utils/color";
@use "utils/shadow";
@use "utils/unit" as u;

@mixin colors($defaultBorderColor, $actionBorderColor, $focusShadow: "") {
	background-color: color.get(neutral100);
	border-color: $defaultBorderColor;

	&:hover {
		border-color: $actionBorderColor;
	}

	&:focus-within {
		background-color: color.get(neutral100);
		border-color: $actionBorderColor;
		box-shadow: $focusShadow;
	}
}

@mixin style-selectors {
	.disabled {
		@extend %form-field-disabled-style;
	}

	.success:not(.disabled) {
		@extend %form-field-success-style;
	}

	.error:not(.disabled) {
		@extend %form-field-error-style;
	}
}

%form-field-base {
	border-radius: u.rem-calc(4);
	border-style: solid;
	border-width: u.rem-calc(1);
	height: u.rem-calc(32);
	line-height: u.rem-calc(32);
	padding: 0 u.rem-calc(12) 0;
	width: 100%;
}

%form-field-default-style {
	@include colors(
		color.get(neutral200),
		color.get(primary700),
		shadow.get(primary)
	);
}

%form-field-disabled-style {
	@include colors(color.get(neutral200), color.get(neutral200));
}

%form-field-success-style {
	@include colors(
		color.get(success900),
		color.get(success900),
		shadow.get(success)
	);
}

%form-field-error-style {
	@include colors(
		color.get(error900),
		color.get(error900),
		shadow.get(error)
	);
}

%form-field-with-affix {
	align-items: center;
	display: inline-flex;
	position: relative;
}

%form-field-affix {
	align-items: center;
	color: color.get(neutral500);
	display: flex;
	flex: 0;
	position: absolute;
	z-index: 1;
}
