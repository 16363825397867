@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.collapse {
	// background-color: red;background-colorbackground-colorbackground-colorbackground-colorbackground-colorbackground-colorbackground-colorbackground-colorbackground-colorbackground-color

	:global {
		.ant-collapse-item {
			.ant-collapse-header {
				padding: u.rem-calc(20);

				.ant-collapse-expand-icon {
					display: flex;
					height: u.rem-calc(24);
					justify-content: center;
					width: u.rem-calc(36);
				}
			}

			.ant-collapse-content .ant-collapse-content-box {
				padding: u.rem-calc(20);
				padding-block: u.rem-calc(10) u.rem-calc(20);
			}
		}

		.ant-collapse-header-text {
			color: color.get(neutral900);
			font-weight: 700;

			@include font.size(16, 24);
		}
	}
}
