@use "utils/font";

.ant-form-item {
	& &-label {
		display: flex;
		font-weight: 500;
		overflow: visible;
		text-transform: uppercase;

		> label {
			line-height: 1.21;
		}
	}

	& &-explain {
		@include font.size(12, 14.52);
	}
}
