@use "utils/breakpoint" as bp;

.layout {
	align-items: stretch;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	&:global(.ant-layout-has-sider) {
		@include bp.down(md) {
			flex-direction: column;
		}
	}
}
