@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.wrap {
	align-items: center;
	display: flex;
	flex-direction: column;
}

.title {
	color: color.get(neutral900);
	font-weight: bold;
	margin: 0 0 u.rem-calc(18);

	@include font.size(20, 24);

	@include bp.down(sm) {
		@include font.size(16, 19);
	}
}

.divider {
	margin: u.rem-calc(2) 0 u.rem-calc(20);
}

.countdown + .progress {
	margin-top: u.rem-calc(30);
}

.progress + .countdown {
	margin-top: u.rem-calc(20);
}
