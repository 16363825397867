@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/unit" as u;

.box {
	background-color: color.get(white);
	border-radius: u.rem-calc(17);

	@include bp.down(md) {
		padding: u.rem-calc(20);
	}

	&.padding-large {
		padding: u.rem-calc(40);
	}

	&.padding-small {
		padding: u.rem-calc(20);
	}
}
