@use "utils/unit" as u;
@use "utils/color";
@use "utils/shadow";
@use "utils/font";

.ant-switch {
	background-color: color.get(white);
	border-color: color.get(neutral300);
	border-style: solid;
	border-width: u.rem-calc(1);

	.ant-switch-inner .ant-switch-inner-unchecked {
		color: color.get(neutral600);
	}

	&.ant-switch-disabled {
		background-color: color.get(neutral100);
		border-color: color.get(neutral300);

		.ant-switch-inner .ant-switch-inner-checked {
			color: color.get(neutral300);
		}

		.ant-switch-inner .ant-switch-inner-unchecked {
			color: color.get(neutral300);
		}
	}

	&.ant-switch-checked:not(.ant-switch-disabled) {
		border-color: color.get(primary900);

		&:focus-within {
			box-shadow: shadow.get(primary);
		}

		.ant-switch-handle::before {
			background-color: color.get(white);
		}
	}

	&:hover:not(.ant-switch-disabled) {
		background: color.get(white);
		border-color: color.get(primary900);
	}
}
