@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.wrap {
	margin-bottom: u.rem-calc(20);
}

.item {
	margin-bottom: 0;
}

.status {
	color: color.get(neutral500);
	display: block;
	margin-top: u.rem-calc(4);
	text-align: right;

	@include font.size(10, 12);
}
