@use "utils/unit" as u;
@use "utils/color";

.wrap {
	position: relative;
	width: fit-content;
}

.avatar {
	border: none;
}

.button {
	background-color: color.get(primary700);
	bottom: u.rem-calc(8);
	position: absolute;
	right: u.rem-calc(8);
}
