@use "utils/font";
@use "utils/unit" as u;

.progress {
	text-transform: uppercase;

	@include font.size(10, 15);

	:global(.ant-progress-bg) {
		height: u.rem-calc(4) !important;
	}

	:global(.ant-progress-layout-bottom .ant-progress-text) {
		align-self: flex-start;
		margin-top: u.rem-calc(8);
	}
}
