@use "utils/color";
@use "utils/unit" as u;

.input:global(.ant-input-outlined) {
	background-color: color.get(white);

	&:not(:global(.ant-input-disabled)) {
		&:hover,
		&:active,
		&:focus-within {
			background-color: color.get(white);
		}
	}
}

.submit:global(.ant-btn.ant-btn-round.ant-btn) {
	float: right;
	padding-inline-end: u.rem-calc(60);
	padding-inline-start: u.rem-calc(60);
	width: auto;
}
