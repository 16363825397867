@use "utils/unit" as u;

.button {
	appearance: none;
	background-color: transparent;
	border: none;
	cursor: pointer;
	height: u.rem-calc(16);
	padding: 0 u.rem-calc(5) 0 u.rem-calc(4);
}
