@use "utils/breakpoint" as bp;
@use "utils/unit" as u;

.content {
	gap: u.rem-calc(20);

	@include bp.down(lg) {
		align-items: center;
		flex-direction: column;
		gap: u.rem-calc(40);
	}
}

.left {
	flex: 0 0 auto;
	margin: 0;
	max-width: u.rem-calc(270);
	width: 32.4%;

	@include bp.down(lg) {
		width: 100%;
	}
}

.right {
	flex: 0 0 auto;
	max-width: u.rem-calc(543);
	width: 65%;

	@include bp.down(lg) {
		max-width: unset;
		width: 100%;
	}
}

.footer {
	justify-content: flex-end;
	margin-top: u.rem-calc(40);

	@include bp.down(lg) {
		justify-content: center;
	}
}

.button {
	max-width: u.rem-calc(334);
}
