@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.container > :not(:first-child) {
	margin-inline-start: u.rem-calc(-14);
}

.more {
	&.clickable {
		cursor: pointer;
		user-select: none;
	}

	&.has-ellipsis :global(.ant-avatar-string) {
		margin-top: u.rem-calc(-7);

		@include font.size(12);
	}

	&.has-more-count :global(.ant-avatar-string) {
		@include font.size(10, 12);
	}
}
