@use "utils/font";
@use "utils/unit" as u;

.item {
	:global(.ant-form-item-label) > label {
		width: 100%;

		&::after {
			display: none;
		}
	}
}

.checkbox {
	margin-left: auto;
	text-transform: none;

	&::after {
		display: none;
	}

	:global(.ant-checkbox) + span {
		padding-inline-end: 0;
	}
}
