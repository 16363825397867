@use "utils/form-field";
@use "utils/unit" as u;

.copyable {
	@extend %form-field-base;
	@extend %form-field-default-style;
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 0 u.rem-calc(11) 0 u.rem-calc(8);

	:global(.ant-typography) {
		flex: 1 1 auto;
		font-size: u.rem-calc(14);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
