@use "config/variables" as vars;
@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.email {
	color: color.get(neutral600);
	font-weight: 300;

	@include font.size(14, 20);
}

.popover {
	padding-top: vars.$headerPadding + u.rem-calc(1);

	@include bp.down(md) {
		padding-top: vars.$headerPaddingMobile + u.rem-calc(1);
	}

	&::before {
		background-color: color.get(white);
		content: "";
		display: block;
		height: vars.$headerPadding;
		left: u.rem-calc(-20);
		position: absolute;
		right: u.rem-calc(-20);
		top: 0;
		z-index: 1;

		@include bp.down(md) {
			height: vars.$headerPaddingMobile;
		}
	}
}
