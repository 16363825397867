@use "utils/color";
@use "utils/unit" as u;

.content {
	margin: 0 auto;
	max-width: u.rem-calc(970);

	:global(.ant-divider) {
		border-color: color.get(neutral200);
	}
}

.title:global(.ant-typography) {
	margin: 0 0 u.rem-calc(40);
}

.divider {
	margin: u.rem-calc(40) 0;
}

.shareform {
	margin: 0 auto;
	max-width: u.rem-calc(730);
}
