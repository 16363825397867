@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

$border: u.rem-calc(1) solid var(--event-border-color);

.wrap {
	--event-border-color: #{color.get(neutral100)};
	box-sizing: border-box;
	display: flex;
	font-family: font.get(inter);

	@include bp.down(xl) {
		flex-direction: column;
		padding: u.rem-calc(20);
	}
}

.details {
	border-right: $border;
	display: flex;
	flex-direction: column;
	padding-right: u.rem-calc(60);
	width: 100%;

	@include bp.down(xl) {
		border-bottom: $border;
		border-right: none;
		margin-bottom: u.rem-calc(20);
		padding-bottom: u.rem-calc(20);
		padding-right: 0;
	}
}

.header {
	align-items: center;
	margin: 0 0 u.rem-calc(14);

	@include bp.down(xl) {
		margin: 0 0 u.rem-calc(20);
	}
}

.content {
	color: color.get(neutral900);
	font-weight: 300;
	margin: 0 0 u.rem-calc(18);

	@include font.size(18, 25);
}

.attendees {
	border-top: $border;
	margin-top: auto;
	padding-top: u.rem-calc(25);

	@include bp.down(xl) {
		padding-top: u.rem-calc(16);
	}
}

.actions {
	max-width: u.rem-calc(334);
	min-width: u.rem-calc(300);
	padding-left: u.rem-calc(60);
	width: 100%;

	@include bp.down(xl) {
		max-width: unset;
		min-width: unset;
		padding-left: 0;
	}
}

.action-button {
	margin-top: u.rem-calc(40);
	width: 100%;
}

.wrap.is-closed {
	--event-border-color: #{color.get(white)};
	background-color: transparent;
	border: $border;
	padding: u.rem-calc(39);

	@include bp.down(xl) {
		padding: u.rem-calc(19);
	}

	.attendees {
		border: none;
		padding-top: 0;
	}
}
