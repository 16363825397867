@use "utils/unit" as u;
@use "utils/font";

.large {
	&:global(.ant-checkbox-wrapper) {
		@include font.size(14, 18);
	}

	:global {
		.ant-checkbox {
			+ span {
				padding-inline-end: u.rem-calc(10);
				padding-inline-start: u.rem-calc(10);
			}

			&-inner {
				height: u.rem-calc(20);
				width: u.rem-calc(20);

				&::after {
					transform: translate(-50%, -50%) scale(1.25);
				}
			}
		}
	}
}
