@use "utils/unit" as u;

.root {
	inset: calc(var(--header-height) - 1px) 0 0;

	:global(.ant-drawer-content-wrapper) {
		box-shadow: none;
	}
}

.content {
	:global(.ant-drawer-body) {
		padding: u.rem-calc(14) u.rem-calc(24) u.rem-calc(16);
	}
}
