@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/unit" as u;

.wrap {
	align-items: stretch;
	display: flex;
	flex: 1 1 auto;
	height: 100%;
}

.sidebar {
	background-color: color.get(white);
	min-width: u.rem-calc(273);

	@include bp.down(md) {
		display: none;
	}

	&-content {
		display: flex;
		flex-direction: column;
		height: calc(100vh - u.rem-calc(82));
		justify-content: space-between;
		overflow: auto;
		padding: u.rem-calc(80) 0 u.rem-calc(40) u.rem-calc(40);
		position: sticky;
		row-gap: u.rem-calc(40);
		top: u.rem-calc(82);
	}
}

.content {
	margin: 0 auto;
	max-width: u.rem-calc(1153);
	padding: u.rem-calc(80) u.rem-calc(40);
	width: 100%;

	@include bp.down(sm) {
		padding: u.rem-calc(40) u.rem-calc(20);
	}
}
