@use "utils/unit" as u;
@use "utils/color";
@use "utils/font";

.wrap {
	display: flex;
	justify-content: center;
}

.inner {
	display: flex;
	flex-direction: column;
	max-width: u.rem-calc(400);
	row-gap: u.rem-calc(20);
	text-align: center;
}

.title {
	@include font.size(40, 40);
}

.subtitle:global(.ant-typography) {
	display: block;

	@include font.size(36, 47);
}

.content > * {
	color: color.get(neutral900);
	font-weight: 300;
	margin: u.rem-calc(18);
	padding: 0;

	@include font.size(18, 25.2);

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.continue-button {
	width: 100%;
}
