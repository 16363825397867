@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.wrap {
	align-items: center;
	column-gap: u.rem-calc(3);
	display: inline-grid;
	grid-template-columns: repeat(3, u.rem-calc(46) auto) u.rem-calc(46);
	grid-template-rows: auto auto;
	row-gap: u.rem-calc(10);
	text-align: center;
}

.label {
	color: color.get(neutral500);
	font-weight: 500;
	grid-row: 1;
	text-transform: uppercase;

	@include font.size(10, 12);
}

.value {
	color: color.get(neutral900);
	font-weight: 700;
	grid-row: 2;

	@include font.size(18, 22);
}

.colon {
	align-self: flex-end;
	color: color.get(neutral900);
	font-weight: 700;
	grid-row: 1 / span 2;

	@include font.size(18, 22);
}
