@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.wrap {
	width: 100%;
}

.date {
	color: color.get(neutral900);
	font-weight: 700;
	white-space: nowrap;

	@include font.size(12, 14.5);
}

.progress {
	height: u.rem-calc(11);

	@include font.size(11, 11);
}
