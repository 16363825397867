@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.wrap {
	background-color: color.get(secondary900);
	border-radius: u.rem-calc(60);
	box-sizing: border-box;
	color: color.get(white);
	display: inline-block;
	font-weight: 700;
	height: u.rem-calc(44);
	letter-spacing: u.rem-calc(1);
	padding: u.rem-calc(8) u.rem-calc(17);

	@include bp.down(md) {
		height: u.rem-calc(23);
		padding: u.rem-calc(1) u.rem-calc(8);
	}
}

.current {
	@include font.size(22, 27);

	@include bp.down(md) {
		@include font.size(18, 22);
	}
}

.max {
	@include font.size(12, 14);

	@include bp.down(md) {
		@include font.size(10, 12);
	}
}
