@use "utils/color";
@use "utils/font";
@use "utils/form-field";
@use "utils/unit" as u;

:global(.ant-select-selector) {
	@extend %form-field-default-style;
}

.disabled :global(.ant-select-selector) {
	@extend %form-field-disabled-style;
}

.success:not(.disabled) {
	:global(.ant-select-selector) {
		@extend %form-field-success-style;
	}

	.statusIcon {
		color: color.get(success900);
	}
}

.error:not(.disabled) {
	:global(.ant-select-selector) {
		@extend %form-field-error-style;
	}

	.statusIcon {
		color: color.get(error900);
	}
}

.wrapper {
	width: 100%;

	:global(.ant-select-selection-wrap) {
		@include font.size(14, 19.6);
	}
}

.select {
	width: 100%;
}

.with-prefix {
	@extend %form-field-with-affix;

	:global(.ant-select-selection-wrap) {
		padding-left: u.rem-calc(32);
	}
}

.with-suffix {
	@extend %form-field-with-affix;
}

.prefix {
	@extend %form-field-affix;

	left: u.rem-calc(12);
}

.suffix {
	@extend %form-field-affix;

	right: u.rem-calc(36);
}
