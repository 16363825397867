@use "config/fonts" as *;
@use "sass:map";

@each $name, $font in $fonts {
	@if map.has-key($font, files) {
		@each $file in map.get($font, files) {
			@font-face {
				@each $prop, $value in $file {
					#{ $prop }: $value;
				}
			}
		}
	}
}
