@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;
@use "utils/breakpoint" as bp;

.wrap {
	align-items: flex-start;
	column-gap: u.rem-calc(40);
	display: flex;

	@include bp.down(xl) {
		flex-direction: column-reverse;
	}
}

.content {
	display: flex;
	flex-direction: column;
	margin-top: u.rem-calc(-40);
	width: 100%;

	@include bp.down(xl) {
		margin-top: 0;
	}
}

.toc {
	border: u.rem-calc(1) solid color.get(neutral200);
	border-radius: u.rem-calc(17);
	display: flex;
	flex-direction: column;
	max-width: u.rem-calc(276);
	padding: u.rem-calc(40) u.rem-calc(30);
	position: sticky;
	row-gap: u.rem-calc(20);
	top: u.rem-calc(102);
	width: 100%;

	@include bp.down(xl) {
		max-width: unset;
		padding: u.rem-calc(20);
		position: static;
	}
}

.toc-item {
	color: color.get(neutral700);
	font-weight: 500;
	text-decoration: underline;
	transform: 0.2s ease color;

	@include font.size(16, 19);

	&:not(:last-of-type) {
		border-bottom: u.rem-calc(1) solid color.get(neutral200);
		padding-bottom: u.rem-calc(20);
	}

	&:hover {
		color: color.get(neutral900);
		text-decoration: underline;
	}
}

.item {
	position: relative;

	&:not(:last-of-type) {
		border-bottom: u.rem-calc(1) solid color.get(neutral200);
		padding-bottom: u.rem-calc(40);
	}

	&-anchor {
		pointer-events: none;
		position: absolute;
		top: u.rem-calc(-82);
		visibility: hidden;

		@include bp.down(md) {
			top: u.rem-calc(-73);
		}
	}

	&-title:global(.ant-typography) {
		font-family: font.get(dm-sans);
		letter-spacing: -1%;
		margin-top: u.rem-calc(40);

		@include font.size(30, 39);
	}

	&-subtitle {
		font-weight: 700;

		@include font.size(16, 19);
	}

	&-content {
		color: color.get(neutral900);
		font-weight: 300;

		& > :first-child {
			margin-top: 0;
			padding-top: 0;
		}

		& > :last-child {
			margin-bottom: 0;
			padding-bottom: 0;
		}

		ul,
		ol {
			display: flex;
			flex-direction: column;
			list-style-position: inside;
			padding: 0;
			row-gap: u.rem-calc(20);
		}
	}
}
