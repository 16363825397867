@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.wrap {
	align-items: center;
	color: color.get(neutral900);
	column-gap: u.rem-calc(14);
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-rows: auto auto;

	@include bp.down(md) {
		align-items: flex-start;
		column-gap: u.rem-calc(4);
	}
}

.size-small {
	align-items: flex-start;
	column-gap: u.rem-calc(6);
	row-gap: u.rem-calc(5);
}

.align-right {
	text-align: right;
}

.avatar {
	grid-column: 1;
	grid-row: 1 / span 2;

	.align-right & {
		grid-column: 3;
	}
}

.heading {
	font-weight: 700;
	grid-column: 2;
	grid-row: 1;
	letter-spacing: -0.01em;

	@include font.size(16, 19.3);

	.size-small & {
		@include font.size(14, 17);
	}

	.size-large & {
		@include font.size(24, 31);

		@include bp.down(md) {
			@include font.size(18, 23.4);
		}
	}
}

.subheading {
	color: color.get(neutral500);
	font-weight: 300;
	grid-column: 2;
	grid-row: 2;

	@include font.size(14, 16);

	.size-small & {
		@include font.size(12, 14.5);
	}

	.size-large & {
		font-weight: 500;
	}
}

.heading,
.subheading {
	a {
		color: inherit;
		text-decoration: none;

		&:hover {
			color: inherit;
		}
	}
}

.children {
	grid-column: 3;
	grid-row: 1 / span 2;

	.align-right & {
		grid-column: 1;
	}
}
