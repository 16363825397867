@use "utils/color";
@use "utils/unit" as u;

.icon {
	height: u.rem-calc(24);
	width: u.rem-calc(24);
}

.active {
	color: color.get(neutral400);
}

.avatar {
	border: none;
}
