@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.wrap {
	color: color.get(neutral900);
}

.count {
	font-weight: bold;
	margin-left: u.rem-calc(2);

	@include font.size(12, 14.52);
}

.label {
	color: color.get(neutral500);
	font-weight: 500;

	@include font.size(12, 14.52);
}
