@use "utils/breakpoint" as bp;
@use "utils/font";
@use "utils/unit" as u;

.wrap {
	display: flex;
	flex-direction: column;
	row-gap: u.rem-calc(30);
}

.title {
	margin-bottom: u.rem-calc(10);
	margin-top: 0;
}

.pre-title:global(.ant-typography) {
	@include font.size(40, 40);

	@include bp.down(sm) {
		@include font.size(30, 30);
	}
}

.post-title:global(.ant-typography) {
	@include font.size(34, 41);

	@include bp.down(sm) {
		@include font.size(24, 29);
	}
}
