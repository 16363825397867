@use "sass:meta";
@use "utils/map";

@function get-colors($colors, $key: null, $keys...) {
	@if meta.type-of($colors) != "map" {
		@return ();
	}

	$baseColors: ();

	@each $key in (text, background, border) {
		@if map.has-key($colors, $key) {
			$baseColors: map.set($baseColors, $key, map.get($colors, $key));
		}
	}

	@if not $key {
		@return $baseColors;
	}

	@return map.merge(
		$baseColors,
		get-colors(map.get($colors, $key, $keys...))
	);
}

@mixin colors($colors, $border: true) {
	background-color: map.get($colors, "background");
	color: map.get($colors, "text");

	@if $border {
		border-color: map.get-default(
			$colors,
			"border",
			map.get($colors, "background")
		);
	}
}

@mixin state($variant, $state: null) {
	@include colors(get-colors($variant, $state));

	@if map.has-key($variant, icon) {
		.ant-btn-icon {
			@include colors(
				if(
					$state,
					get-colors($variant, icon, $state),
					get-colors($variant, icon)
				),
				false
			);
		}
	}
}

@mixin variant($variant) {
	&:disabled,
	&.ant-btn-disabled {
		@include state($variant, disabled);
	}

	&:not(:disabled):not(.ant-btn-disabled) {
		@include state($variant);

		&:hover,
		&:active {
			@include state($variant, active);
		}
	}

	@if map.has-key($variant, shadow) {
		&:not(:disabled):not(.ant-btn-disabled):focus-within {
			box-shadow: map.get($variant, shadow);
		}
	}
}
