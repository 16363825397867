@use "utils/breakpoint" as bp;
@use "utils/font";
@use "utils/unit" as u;

.heading:global(.ant-typography) {
	margin: 0 0 u.rem-calc(30);

	@include font.size(16, 19.36);
}

.wrap {
	// :global(.ant-row) {
	// 	@include bp-down(448) {

	// 	}
	// }

	:global(.ant-row:last-child .ant-form-item) {
		margin-bottom: 0;
	}
}
