@use "utils/color";
@use "utils/font";
@use "utils/form-field";
@use "utils/unit" as u;

.input {
	@extend %form-field-base;
	@extend %form-field-default-style;
}

.tag {
	background-color: color.get(secondary900);
	border-radius: u.rem-calc(8);
	color: color.get(white);
	display: flex;
	margin-top: u.rem-calc(4);
	padding-left: u.rem-calc(8);

	@include font.size(10, 16);
}

.tags {
	display: flex;
	flex-wrap: wrap;
	gap: 0 u.rem-calc(4);
}
