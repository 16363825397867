@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.button {
	align-items: center;
	background: color.get(white);
	border: u.rem-calc(1) solid color.get(neutral600);
	border-radius: u.rem-calc(4);
	color: color.get(neutral900);
	cursor: pointer;
	display: flex;
	font-weight: 500;
	gap: u.rem-calc(10);
	min-width: u.rem-calc(175);
	padding: u.rem-calc(9) u.rem-calc(11);

	@include font.size(14, 14);
}
