@use "utils/breakpoint" as bp;
@use "utils/font";
@use "utils/unit" as u;

%padding {
	padding-left: u.rem-calc(40);
	padding-right: u.rem-calc(40);

	@include bp.down(md) {
		padding-left: u.rem-calc(20);
		padding-right: u.rem-calc(20);
	}
}

.row {
	padding-bottom: u.rem-calc(14);
	padding-top: u.rem-calc(14);

	@extend %padding;
}

.side {
	flex: 1 1 50%;
}

.button {
	@include bp.down(md) {
		justify-content: flex-start;
		max-width: u.rem-calc(20);
		overflow: hidden;
	}
}

.title:global(.ant-typography) {
	flex: 0 0 auto;
	font-weight: 700;
	margin: 0;

	@include font.size(18, 22);

	@include bp.down(sm) {
		display: none;
	}
}

.content {
	flex: 1 1 auto;
	margin: u.rem-calc(40) auto u.rem-calc(60);
	max-width: u.rem-calc(1206);
	width: 100%;

	@extend %padding;

	@include bp.down(md) {
		margin: u.rem-calc(20) auto u.rem-calc(44);
	}
}

.mobile {
	@include bp.up(sm) {
		display: none;
	}
}
