@use "utils/unit" as u;
@use "utils/color";

.drawer {
	:global(.ant-divider) {
		margin: 0;
	}
}

.column {
	height: 100%;

	> :last-child {
		margin-top: auto;
	}
}
