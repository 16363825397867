@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/unit" as u;

.wrap {
	display: flex;
	gap: u.rem-calc(40);
	margin-bottom: 0;

	@include bp.down(lg) {
		gap: u.rem-calc(20);
	}

	@include bp.down(md) {
		flex-direction: column;
		gap: 0;
	}
}

.content {
	display: flex;
	flex: 1 1 100%;
	flex-direction: column;
}

.sidebar {
	flex: 0 0 auto;
	margin: 0 auto u.rem-calc(40);
	max-width: u.rem-calc(310);
	min-width: u.rem-calc(240);
	width: 30%;

	@include bp.down(md) {
		margin-bottom: u.rem-calc(20);
		width: 100%;
	}
}
