@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.avatar {
	border-width: 0;
}

.border {
	border: solid u.rem-calc(3) color.get(primary700);

	&-large {
		border-width: u.rem-calc(4);

		@include bp.down(sm) {
			border-width: u.rem-calc(2);
		}
	}
}

.color {
	&-primary {
		background-color: color.get(primary700);
		border-color: color.get(primary700);
	}

	&-secondary {
		background-color: color.get(secondary900);
		border-color: color.get(secondary900);
	}
}
