@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.item {
	height: u.rem-calc(40);
	padding: u.rem-calc(10) u.rem-calc(22);

	@include font.size(16, 24);
}
