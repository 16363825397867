/**
 * Font functions
 */

@use "config/variables" as *;
@use "utils/unit" as u;
@use "sass:map";
@use "sass:math";
@use "sass:meta";

$fonts: () !default;

@function get($font) {
	@if map.has-key($fonts, $font) {
		@return map.get($fonts, $font, family);
	} @else {
		@warn "Invalid font: #{$font}.";

		@return null;
	}
}

@mixin size($size, $line-height: null) {
	font-size: u.rem-calc($size);

	@if (meta.type-of($line-height) == "number") {
		$calculated: math.div($line-height, $size);
		$round: math.div(math.round($calculated * 10000), 10000);

		@if ($calculated != $round) {
			$calculated: calc(#{$line-height}em / #{$size});
		}

		line-height: $calculated;
	}
}

@mixin class($name, $family, $weight: null) {
	.font-#{$name} {
		font-family: $family;

		@if meta.type-of($weight) == number {
			font-weight: $weight;
		}
	}
}
