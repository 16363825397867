@use "utils/color";
@use "utils/form-field";
@use "utils/unit" as u;

.ant-input {
	font-weight: 400;

	&::placeholder {
		font-weight: 300;
	}

	&-lg {
		height: u.rem-calc(48);
	}

	&-prefix {
		color: color.get(neutral500);
		margin-inline-end: u.rem-calc(8);
	}

	&-suffix {
		color: color.get(neutral500);
		margin-inline-start: u.rem-calc(8);
	}

	&-suffix &-clear-icon {
		margin: 0 0 0 u.rem-calc(14);
		order: 2;
	}

	&-clear-icon-hidden {
		display: none;
	}

	&-affix-wrapper {
		@extend %form-field-default-style;
		display: flex;
		height: u.rem-calc(34);
		padding: 0 u.rem-calc(12);

		&-lg {
			height: u.rem-calc(48);

			.ant-input-prefix {
				margin-inline-end: u.rem-calc(12);
			}

			.ant-input-suffix {
				margin-inline-start: u.rem-calc(12);
			}
		}
	}

	&-outlined {
		&:not(.ant-input-disabled).ant-input-status {
			&-error {
				@extend %form-field-error-style;
			}

			&-success {
				@extend %form-field-success-style;

				.ant-input-suffix {
					color: color.get(success900);
				}
			}
		}

		&.ant-input-disabled {
			@extend %form-field-disabled-style;
		}
	}
}
