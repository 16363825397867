@use "utils/unit" as u;

.wrap {
	display: flex;
	flex-direction: column;
	gap: u.rem-calc(12);

	:global(.ant-upload-select) {
		order: 2;
	}

	:global(.ant-upload-list) {
		order: 1;
	}
}

.button {
	display: flex;
}
