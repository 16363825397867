@use "utils/unit" as u;

.form {
	display: flex;
	flex-direction: column;
	gap: u.rem-calc(20);
}

.button {
	align-self: flex-end;
	width: auto;
}
