@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/unit" as u;

.wrap {
	background-color: color.get(white);
	flex: auto;
	width: 0;

	@include bp.down(md) {
		width: 100%;
	}
}

.content {
	align-items: stretch;
	display: flex;
	flex-direction: column;
	gap: u.rem-calc(40);
	padding: u.rem-calc(40);

	@include bp.between(md, xl) {
		padding-left: u.rem-calc(20);
		padding-right: u.rem-calc(20);
	}

	@include bp.down(sm) {
		padding-left: u.rem-calc(20);
		padding-right: u.rem-calc(20);
	}
}

.inner {
	align-items: stretch;
	display: flex;
	flex-direction: column;
	gap: u.rem-calc(40);
	margin: 0 auto;
	max-width: u.rem-calc(440);
	width: 100%;
}

.sider {
	@include bp.down(md) {
		max-width: 100% !important;
		width: 100% !important;
	}
}

.image {
	height: 100%;
	max-width: 100%;
	object-fit: cover;
	width: 100%;
}
