@use "utils/color";
@use "utils/font";
@use "utils/shadow";
@use "utils/unit" as u;

.wrap {
	column-gap: u.rem-calc(15.5);
	display: flex;
}

.reaction {
	align-items: center;
	column-gap: u.rem-calc(4);
	display: flex;
}

.emoji {
	align-items: center;
	background-color: color.get(neutral100);
	border-radius: 50%;
	display: flex;
	height: u.rem-calc(28);
	justify-content: center;
	width: u.rem-calc(28);

	@include font.size(18, 18);
}

.count {
	font-weight: bold;

	@include font.size(10, 14);
}
