@use "utils/font";
@use "utils/breakpoint" as bp;

.logo {
	display: inline-block;

	@include font.size(28, 41);

	@include bp.down(md) {
		@include font.size(18, 26);
	}
}
