@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.wrap {
	border: u.rem-calc(2) solid color.get(secondary900);
	border-radius: 0 u.rem-calc(20) u.rem-calc(20) u.rem-calc(20);
	color: color.get(neutral900);
	padding: u.rem-calc(38);
}

.title {
	font-weight: 500;
	margin: 0 0 u.rem-calc(15);

	@include font.size(20, 28);
}

.description {
	font-weight: 300;
	margin: u.rem-calc(15) 0 0;

	@include font.size(18, 25.2);
}
