@use "utils/color";
@use "utils/font";
@use "utils/shadow";
@use "utils/unit" as u;

.wrap {
	background: color.get(white);
	border-radius: u.rem-calc(20) 0 u.rem-calc(20) u.rem-calc(20);
	box-shadow: shadow.get(neutral);
	color: color.get(neutral900);
	display: flex;
	flex-direction: column;
	padding: u.rem-calc(20) u.rem-calc(30);
	row-gap: u.rem-calc(15);
}

.media {
	margin-bottom: u.rem-calc(15);
}

.content {
	font-weight: 300;

	@include font.size(18, 25);

	> :first-child {
		margin-top: 0;
		padding-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
		padding-bottom: 0;
	}
}

.divider {
	margin: 0;
}

.react-button {
	align-self: flex-start;

	&:not(:disabled):not(:global(.ant-btn-disabled)) {
		color: color.get(neutral500);

		&:hover,
		&:active {
			color: color.get(primary900) !important;
		}
	}
}
