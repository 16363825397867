@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/unit" as u;
@use "config/variables" as vars;

.header {
	border-bottom: u.rem-calc(1) solid color.get(neutral100);
	box-shadow: 0 u.rem-calc(4) u.rem-calc(4) 0 #0000000d;
	height: auto;
	line-height: 1;
	padding: vars.$headerPadding u.rem-calc(40);
	position: sticky;
	top: 0;
	z-index: 1;

	@include bp.down(md) {
		padding: vars.$headerPaddingMobile u.rem-calc(24);
	}

	@include bp.down(sm) {
		position: sticky;
	}
}
