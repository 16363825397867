@use "utils/color";
@use "utils/unit" as u;

.centered {
	text-align: center;
}

.title:global(.ant-typography) {
	line-height: 1;
	margin: 0;
}

.subtitle {
	color: color.get(neutral800);
	font-weight: 300;
}
