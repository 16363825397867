@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.wrap {
	background-color: color.get(white);
	border: u.rem-calc(2) solid color.get(neutral200);
	border-radius: u.rem-calc(20);
	padding: u.rem-calc(14);
	position: relative;
}

.content-item {
	margin-bottom: u.rem-calc(14);

	:global(.ant-form-item-control),
	:global(.ant-form-item-control-input) {
		position: static;
	}

	.content-input:global(.ant-input-textarea-affix-wrapper) {
		background: transparent;
		border: none;
		box-shadow: none;
		position: static;

		&:hover,
		&:focus-within {
			background: transparent;
			border: none;
			box-shadow: none;
		}

		:global(.ant-input) {
			padding: 0;
		}

		:global(.ant-input-data-count) {
			bottom: u.rem-calc(14);
			color: color.get(neutral500);
			right: u.rem-calc(14);

			@include font.size(12, 14.5);
		}
	}
}

.attachments-item {
	margin-bottom: 0;
}

.attachments-button {
	display: block;
}
