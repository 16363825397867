@use "utils/breakpoint" as bp;
@use "utils/font";

.pre {
	line-height: 1;

	.medium & {
		@include font.size(28);
	}

	.large & {
		@include font.size(40);

		@include bp.down(sm) {
			@include font.size(30);
		}
	}
}

.post {
	@include font.size(16, 24);

	.medium & {
		@include font.size(24, 27);
	}

	.large & {
		letter-spacing: -0.01em;

		@include font.size(36, 47);

		@include bp.down(sm) {
			@include font.size(24, 29.05);
		}

		strong {
			font-weight: 700;
		}
	}
}
