@use "utils/color";
@use "utils/font";
@use "utils/shadow";
@use "utils/unit" as u;

.button {
	column-gap: u.rem-calc(10);
	font-weight: 600;
	text-decoration: none;

	&:not(:disabled):not(:global(.ant-btn-disabled)) {
		color: color.get(neutral500);

		&:global(.ant-popover-open),
		&:hover,
		&:active {
			color: color.get(primary900) !important;
		}
	}
}

.react-buttons {
	column-gap: u.rem-calc(4);
	display: flex;
}

.react-button {
	background-color: transparent !important;
	border: none !important;

	@include font.size(18, 18);
}
