@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.title {
	font-weight: 700;

	@include font.size(16, 19);
}

.close:global(.ant-btn) {
	color: color.get(neutral600);
	height: u.rem-calc(24);
	width: u.rem-calc(24);
}
