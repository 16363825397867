@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.wrap {
	margin: 0 auto;
	width: 100%;
}

.button {
	max-width: u.rem-calc(344);
}

.header {
	margin-bottom: u.rem-calc(40);
}

.footer {
	align-items: center;
	margin-top: u.rem-calc(30);

	&-text {
		@include font.size(14);
	}

	&:not(.centered) {
		@include bp.up(lg) {
			align-items: flex-end;
		}
	}
}
