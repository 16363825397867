@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.item {
	height: u.rem-calc(56);
	padding: u.rem-calc(18) u.rem-calc(22);

	@include font.size(16, 19.3);

	&.active {
		background-color: color.get(neutral100);
	}
}

.navigation {
	&.mobile {
		row-gap: u.rem-calc(14);

		.item.active {
			border-radius: u.rem-calc(38);
		}
	}

	&.desktop {
		row-gap: u.rem-calc(40);

		.item.active {
			border-bottom-left-radius: u.rem-calc(38);
			border-top-left-radius: u.rem-calc(38);
		}
	}
}
