@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.button {
	align-items: center;
	background: color.get(facebook);
	border: none;
	border-radius: u.rem-calc(4);
	color: color.get(white);
	cursor: pointer;
	display: flex;
	font-weight: 500;
	gap: u.rem-calc(8);
	letter-spacing: 0.005em;
	min-width: u.rem-calc(245);
	padding: u.rem-calc(6) u.rem-calc(15) u.rem-calc(6) u.rem-calc(6);

	@include font.size(16, 16);
}
