@use "utils/color";
@use "utils/font";

.footer {
	text-align: center;
}

.text {
	color: color.get(neutral800);
	font-weight: 300;

	@include font.size(10, 14);
}
