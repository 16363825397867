@use "antd";
@use "config/variables";
@use "fonts";
@use "sass:map";
@use "utils/color";
@use "utils/font";

:root {
	@each $name, $shadow in variables.$colors {
		--#{"color-" + $name}: #{$shadow};
	}

	@each $name, $shadow in variables.$shadows {
		--#{"shadow-" + $name}: #{$shadow};
	}

	@each $name, $font in variables.$fonts {
		--#{"font-" + $name}: #{map.get($font, family)};
	}
}

html {
	height: 100%;
}

body {
	background-color: color.get(neutral100);
	font-family: font.get(inter);
	height: 100%;
	margin: 0;
}

#root {
	height: 100%;
}
