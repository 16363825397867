@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

$border: u.rem-calc(2) solid color.get(neutral100);

%checked {
	border-color: color.get(secondary900);
	color: color.get(neutral800);
}

.ant-radio-button-wrapper {
	border: $border;
	border-radius: u.rem-calc(5);
	color: color.get(neutral400);
	font-family: font.get(inter);
	font-weight: 700;
	height: auto;
	min-width: u.rem-calc(48);
	padding: u.rem-calc(6);
	text-align: center;

	@include font.size(16, 32);

	&:first-child {
		border-inline-start: $border;
	}

	&:not(:first-child)::before {
		display: none;
	}

	+ .ant-radio-button-wrapper {
		margin-left: u.rem-calc(14);
	}

	&:hover {
		@extend %checked;

		&:first-child {
			border-inline-start-color: color.get(secondary900);
		}
	}

	&-checked:not(.ant-radio-button-wrapper-disabled) {
		&,
		&:first-child,
		&:hover {
			@extend %checked;
		}
	}
}
