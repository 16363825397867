@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.title:global(.ant-typography) {
	font-weight: 700;
	margin: 0 !important;

	@include font.size(34, 41);
}

.header {
	margin-bottom: u.rem-calc(40);
}
