@use "utils/breakpoint" as bp;
@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;

.author {
	margin-bottom: u.rem-calc(20);
}

.message {
	color: color.get(neutral900);
	font-style: italic;
	font-weight: 200;
	margin: u.rem-calc(30) 0 0;
	text-align: center;

	@include font.size(18, 25.2);
}

.responses {
	margin-bottom: u.rem-calc(80);
	margin-left: u.rem-calc(53);
	margin-top: u.rem-calc(59);

	.message + & {
		margin-top: u.rem-calc(30);
	}
}

.footer {
	background-color: color.get(neutral100);
	bottom: 0;
	margin-top: auto;
	padding-bottom: u.rem-calc(60);
	position: sticky;

	@include bp.down(lg) {
		padding-bottom: u.rem-calc(40);
	}

	@include bp.down(md) {
		padding-bottom: u.rem-calc(20);
	}

	&::before {
		background: linear-gradient(
			180deg,
			rgba(247, 250, 251, 0) 0%,
			rgba(247, 250, 251, 0.5) 85.87%,
			#fafbfd 113.59%
		);
		bottom: 100%;
		content: "";
		display: block;
		height: u.rem-calc(77);
		left: 0;
		position: absolute;
		right: 0;
	}
}
