@use "utils/font";
@use "utils/unit" as u;
@use "config" as *;
@use "utils" as *;

.ant-btn {
	line-height: 1.4;

	&-sm {
		@include font.size(14, 17);

		&.ant-btn.ant-btn-circle {
			min-width: u.rem-calc(28);
		}

		&.ant-btn-icon-only {
			width: u.rem-calc(28);
		}
	}

	&-lg {
		@include font.size(18, 22);
	}

	// duplicate of `.ant-btn` selector is intentional
	&.ant-btn-round.ant-btn {
		border-radius: u.rem-calc(38);
		padding-inline-end: u.rem-calc(24);
		padding-inline-start: u.rem-calc(24);

		&.ant-btn-sm {
			padding-inline-end: u.rem-calc(12);
			padding-inline-start: u.rem-calc(12);
		}
	}

	&-variant-default,
	&-variant-outlined,
	&-variant-solid {
		.ant-btn-icon {
			align-items: center;
			border-radius: 50%;
			display: flex;
			flex: 0 0 auto;
			height: u.rem-calc(30);
			justify-content: center;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
			width: u.rem-calc(30);
		}

		&.ant-btn-sm .ant-btn-icon {
			height: u.rem-calc(20);
			width: u.rem-calc(20);
		}
	}

	@each $color, $variants in $buttons {
		&-color-#{$color} {
			@each $variant, $config in $variants {
				&.ant-btn-variant-#{$variant} {
					@include variant($config);
				}
			}
		}
	}

	&-variant-link {
		font-weight: 600;
		height: auto;
		padding: 0;
		text-decoration: underline;

		@include font.size(12, 14.5);

		&.ant-btn-sm {
			text-decoration: none;

			@include font.size(10, 14);
		}

		&.ant-btn-lg {
			font-weight: 700;

			@include font.size(14, 17);
		}
	}
}
