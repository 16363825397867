@use "utils/color";
@use "utils/unit" as u;

.navigation {
	display: flex;
	flex-direction: column;
}

.item {
	align-items: center;
	box-sizing: border-box;
	color: color.get(neutral600);
	column-gap: u.rem-calc(14);
	display: flex;
	font-weight: 400;
	text-decoration: none;
	transition: 0.15s ease all;

	&.active,
	&:not(.active):hover {
		color: color.get(neutral900);
	}
}
