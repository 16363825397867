@use "utils/color";
@use "utils/breakpoint" as bp;
@use "utils/font";
@use "utils/unit" as u;

%font-size {
	@include font.size(10, 14);

	@include bp.down(sm) {
		@include font.size(17, 23.8);
	}
}

.wrap {
	@extend %font-size;

	color: color.get(neutral800);
	font-family: font.get(inter);
	font-weight: 300;

	.item {
		color: color.get(neutral800);
		padding: 0 0 u.rem-calc(14);

		&:not(:first-child) {
			padding-top: u.rem-calc(14);
		}
	}

	:global(.ant-list-footer) {
		@include bp.down(sm) {
			padding-top: u.rem-calc(40);
		}
	}
}

.footer {
	font-weight: 300;
	text-align: center;
}

.footer-header {
	font-weight: 500;
	margin-top: 0;
}

.modal-button {
	@extend %font-size;

	font-weight: 500;
}
