@use "utils/font";
@use "utils/unit" as u;

.horizontal {
	align-items: center;
	column-gap: u.rem-calc(6);
	justify-content: space-between;
}

.vertical {
	flex-direction: column-reverse;
	gap: u.rem-calc(18);
}

.label {
	font-weight: 700;

	@include font.size(12, 14.52);
}
