@use "utils/color";
@use "utils/font";
@use "utils/unit" as u;
@use "utils/breakpoint" as bp;

%tip-header {
	color: color.get(neutral900);
	font-weight: bold;

	@include font.size(24, 31.2);

	@include bp.down(sm) {
		@include font.size(18, 23.4);
	}
}

.modal-mask:global(.ant-modal-mask) {
	backdrop-filter: blur(3px);
	background-color: #0611721a;

	@include bp.down(sm) {
		display: none;
	}
}

.modal-wrapper:global(.ant-modal-wrap) {
	display: flex;
	justify-content: center;

	@include bp.down(sm) {
		border-top: u.rem-calc(1) solid color.get(neutral100);
		top: u.rem-calc(72);
	}
}

.modal:global(.ant-modal) {
	font-family: font.get(inter);
	margin-left: u.rem-calc(20);
	margin-right: u.rem-calc(20);
	max-width: u.rem-calc(900);
	top: u.rem-calc(120);

	@include bp.down(sm) {
		margin: 0;
		max-width: 100vw;
		padding-bottom: 0;
		top: 0;
	}

	:global(.ant-modal-content) {
		bottom: u.rem-calc(60);
		padding: u.rem-calc(40);

		@include bp.down(sm) {
			border-radius: 0;
			bottom: u.rem-calc(0);
			padding: u.rem-calc(20);
		}
	}
}

.title {
	font-weight: bold;
	margin: 0 0 u.rem-calc(30);

	@include font.size(30, 39);

	@include bp.down(sm) {
		margin: 0 0 u.rem-calc(40);

		@include font.size(20, 26);
	}
}

.content {
	display: flex;
	flex-direction: column;
	margin: 0;
	padding-left: u.rem-calc(36);
	row-gap: u.rem-calc(20);

	@include font.size(18, 25.2);

	@include bp.down(sm) {
		padding-left: u.rem-calc(24);

		@include font.size(17, 23.8);
	}
}

.tip {
	display: flex;
	flex-direction: column;
	row-gap: u.rem-calc(14);

	&.with-hint {
		column-gap: u.rem-calc(14);
		display: grid;
		grid-template-columns: auto auto;
		grid-template-rows: auto 1fr auto auto;

		@include bp.down(sm) {
			display: flex;
		}
	}

	&-header {
		@extend %tip-header;
		grid-column: 1;
		grid-row: 1;

		margin: 0 0 0 u.rem-calc(-24);

		@include bp.down(sm) {
			margin-left: u.rem-calc(-18);
		}
	}

	&-content {
		color: color.get(neutral800);
		font-weight: 300;
		grid-column: 1;
		grid-row: 2;
		margin: 0;
	}
}

.hint {
	color: color.get(secondary900);
	grid-column: 2;
	grid-row: 1 / span 3;
	max-width: u.rem-calc(300);

	@include bp.down(sm) {
		max-width: unset;
	}

	&-header {
		align-items: center;
		column-gap: u.rem-calc(10);
		display: flex;
		font-weight: 700;
		justify-content: flex-end;

		@include font.size(20, 24);
	}

	&-icon {
		align-items: center;
		background-color: color.get(secondary900);
		border-radius: 50%;
		color: color.get(white);
		display: flex;
		height: u.rem-calc(30);
		justify-content: center;
		width: u.rem-calc(30);
	}

	&-content {
		background-color: color.get(secondary100);
		border: u.rem-calc(2) solid color.get(secondary900);
		border-radius: u.rem-calc(17) 0 u.rem-calc(17) u.rem-calc(17);
		margin: u.rem-calc(10) 0 0;
		padding: u.rem-calc(24);

		@include font.size(18, 25);
	}
}

.examples {
	&-header {
		font-weight: 700;
		grid-column: 1;
		grid-row: 3;
		margin: 0;

		@include font.size(20, 24.2);

		@include bp.down(sm) {
			@include font.size(17, 23.8);
		}
	}

	&-list {
		column-gap: u.rem-calc(24);
		display: flex;
		grid-column: 1 / span 2;
		grid-row: 4;

		@include bp.down(sm) {
			flex-direction: column;
			row-gap: u.rem-calc(8);
		}
	}
}

.example {
	background-color: color.get(neutral100);
	border-radius: u.rem-calc(17);
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: u.rem-calc(20);
	row-gap: u.rem-calc(10);

	@include bp.down(sm) {
		padding: u.rem-calc(12);
	}

	&-header {
		font-weight: 600;
		margin: 0;
	}

	&-content {
		color: color.get(neutral800);
		font-weight: 300;
		margin: 0;
	}

	&-bad .example-header {
		color: color.get(error900);
	}

	&-good .example-header {
		color: color.get(success900);
	}
}
