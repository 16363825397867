@use "utils/color";
@use "utils/unit" as u;

.activity:global(.ant-list-item) {
	padding: u.rem-calc(14) 0;

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		padding-bottom: 0;
	}
}

.activity-heading {
	font-weight: 400;

	a {
		color: color.get(neutral900);
		text-decoration: none;
	}
}
