@use "utils/unit" as u;

.wrap {
	display: flex;
	flex-wrap: wrap;
	gap: u.rem-calc(20);
	justify-content: flex-end;
}

.image {
	align-self: flex-end;
	border-radius: u.rem-calc(3);
	display: block;
	height: 100%;
	max-height: u.rem-calc(390);
	max-width: u.rem-calc(220);
	position: relative;
	width: auto;
}

.audio {
	flex: 1;
	flex-basis: 100%;
}

.video {
	flex: 1;
	flex-basis: 100%;
	max-height: u.rem-calc(500);
	max-width: 100%;
}
