.button {
	background-color: transparent;
	border: none;
	box-shadow: none;
	cursor: pointer;
	height: auto;
	padding: 0;

	&:hover,
	&:focus,
	&:active {
		background-color: transparent !important;
		color: inherit !important;
	}
}
