@use "utils/color";
@use "utils/unit" as u;

.fields {
	margin: 0 auto;
	max-width: u.rem-calc(360);
}

.divider {
	border-color: color.get(neutral200);
	margin: u.rem-calc(30) auto;
}
