@use "utils/font";
@use "utils/color";
@use "utils/unit" as u;
@use "utils/breakpoint" as bp;

.content {
	align-items: flex-start;
	column-gap: u.rem-calc(40);
	display: flex;

	@include bp.down(sm) {
		flex-direction: column;
	}
}

.column {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;

	@include bp.down(sm) {
		align-items: center;
	}
}

.details {
	column-gap: u.rem-calc(40);
	display: grid;
	flex: 2;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr auto;
	margin-top: u.rem-calc(77);
	row-gap: u.rem-calc(8);

	@include bp.down(md) {
		display: flex;
		flex: 1;
		flex-direction: column;
		row-gap: u.rem-calc(8);
		width: 100%;
	}

	@include bp.down(sm) {
		align-items: center;
		text-align: center;
	}
}

.avatar {
	margin-bottom: u.rem-calc(14);
}

.greet {
	display: block;

	@include bp.down(sm) {
		text-align: center;
	}
}

.username {
	display: block;

	@include bp.down(sm) {
		text-align: center;
	}
}

.bio-title:global(.ant-typography) {
	grid-column: 1;
	grid-row: 1;
	letter-spacing: -0.01em;
	margin: 0;

	@include font.size(24, 31);
}

.bio {
	font-weight: 300;
	grid-column: 1;
	grid-row: 2;
}

.contact {
	display: flex;
	flex-direction: column;
	grid-column: 2;
	grid-row: 2;
	list-style: none;
	margin: 0;
	padding: 0;
	row-gap: u.rem-calc(10);

	@include bp.down(md) {
		margin-top: u.rem-calc(24);
	}

	&-item {
		align-items: center;
		column-gap: u.rem-calc(10);
		display: flex;
		font-weight: 300;

		@include font.size(14, 19);
	}

	&-icon {
		align-items: center;
		color: color.get(primary900);
		display: flex;
		height: u.rem-calc(24);
		justify-content: center;
		width: u.rem-calc(24);
	}
}
