@use "utils/color";
@use "utils/font";
@use "utils/form-field";
@use "utils/unit" as u;

.wrapper {
	@extend %form-field-default-style;

	border-radius: u.rem-calc(6);
	width: 100%;
}

@include form-field.style-selectors;

.textarea {
	border-color: inherit;
	font-size: 14px;
	line-height: 1.4 !important;
	min-height: u.rem-calc(42) !important;
	padding-bottom: u.rem-calc(10);
	padding-top: u.rem-calc(10);

	&:hover {
		border-color: inherit;
	}

	&:focus-within {
		border-color: inherit;
		box-shadow: inherit;
	}
}

.with-prefix {
	@extend %form-field-with-affix;

	.textarea {
		padding-left: u.rem-calc(36);
	}
}

.with-suffix {
	@extend %form-field-with-affix;

	.textarea {
		padding-right: u.rem-calc(36);
	}
}

.prefix {
	@extend %form-field-affix;

	left: u.rem-calc(12);
}

.suffix {
	@extend %form-field-affix;

	right: u.rem-calc(12);
}
