@use "utils/unit" as u;
@use "utils/breakpoint" as bp;
@use "utils/font";

.wrap {
	gap: u.rem-calc(40);
	margin: auto;
	max-width: u.rem-calc(625);

	@include bp.down(md) {
		gap: u.rem-calc(20);
	}
}

.title:global(.ant-typography) {
	margin: 0 0 u.rem-calc(40);
	text-align: center;
}

.heading {
	margin: 0 0 u.rem-calc(20);

	:global(.ant-typography) {
		font-weight: 700;

		@include font.size(16, 19.36);
	}
}

.form {
	flex: 1 1 100%;
}

.actions {
	align-items: center;
	flex-direction: column;
	gap: u.rem-calc(24);
}

.sidebar {
	flex: 0 0 auto;
	max-width: u.rem-calc(200);
	min-width: u.rem-calc(180);
	width: 32%;

	@include bp.down(sm) {
		display: none;
	}
}
